<template>
  <v-container fluid>
    <v-row class="mb-3">
      <v-col class="d-flex align-center justify-space-between flex-wrap">
        <v-btn depressed small color="primary" @click="downloadExcel" :loading="isFetching || tableLoading">
          <v-icon left color="white">{{ icons.mdiDownloadOutline }}</v-icon>
          下載 Excel
        </v-btn>

        <v-btn depressed small color="secondary" :to="{ name: 'AddCheckIn' }">
          新增打卡紀錄
        </v-btn>
      </v-col>
    </v-row>
    <v-form @submit.prevent="submitSearch" :disabled="isFetching || tableLoading">
      <v-row>
        <v-col cols="12" md="3">
          <FormControl inputType="monthRangePicker" :inputValue.sync="filterMonth" label="月份" :dense="true" />
        </v-col>
        <v-col cols="12" md="3">
          <FormControl
            inputType="select"
            :inputValue.sync="filterStaff"
            label="職員"
            :options="staffList"
            :dense="true"
          />
        </v-col>
        <v-col cols="12" md="3">
          <FormControl
            inputType="select"
            :inputValue.sync="filterType"
            label="類型"
            :options="checkInTypeList"
            :dense="true"
          />
        </v-col>
        <v-col cols="12" md="3" class="d-flex align-center">
          <v-btn
            depressed
            color="success darken-4"
            class="mr-6 white--text"
            type="submit"
            :loading="isFetching || tableLoading"
            small
            >搜尋</v-btn
          >
          <v-btn depressed color="error" @click.prevent="clearSearch" :disabled="isFetching || tableLoading" small
            >清除</v-btn
          >
        </v-col>
      </v-row>
    </v-form>

    <v-row class="mt-4">
      <v-col>
        <h3 v-if="$validate.DataValid(otCount)">{{ otCount }}</h3>
        <h3 v-if="$validate.DataValid(lateCount)">{{ lateCount }}</h3>
      </v-col>
    </v-row>

    <v-row class="mt-6">
      <v-col cols="12">
        <Datatable
          :table-headers="tableHeaders"
          :table-data="formData"
          :page="tablePage"
          :page-limit="tablePageLimit"
          :page-limit-options="tablePageLimitOptions"
          :item-total="formItemTotal"
          :is-loading="tableLoading"
          :disable-pagination="tableLoading || isFetching"
          @options-update="onTableChange"
        >
          <template v-slot:[`item.time`]="{ item, index }">
            <div class="d-flex align-center">
              <CustomDigitalTimepicker
                :time.sync="formData[index].time"
                :disableMenu="isFetching || tableLoading || !item.editable"
                :showSecondField="true"
                :dense="true"
                :showError="item.showError"
              ></CustomDigitalTimepicker>

              <template v-if="item.late">
                <span class="ml-2" style="min-width: fit-content; color: #ad2222">(遲到)</span>
              </template>

              <template v-if="item.ot">
                <span class="ml-2" style="min-width: fit-content; color: #e08d07">(加班)</span>
              </template>
            </div>
          </template>

          <template v-slot:[`item.saveBtn`]="{ item, index }">
            <v-btn
              depressed
              small
              color="success"
              @click="updateCheckInTime(index)"
              :loading="isFetching || tableLoading"
              :disabled="!item.editable"
              >儲存</v-btn
            >
          </template>
        </Datatable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FormControl from '@/components/form/FormControl'
import Datatable from '@/components/Datatable.vue'
import { mdiDownloadOutline } from '@mdi/js'
import CustomDigitalTimepicker from '@/components/CustomDigitalTimepicker.vue'
import * as XLSX from 'xlsx'

export default {
  name: 'CheckIn',
  components: {
    Datatable,
    FormControl,
    CustomDigitalTimepicker,
  },
  data() {
    return {
      isFetching: false,
      tableLoading: false,
      icons: {
        mdiDownloadOutline,
      },
      tableHeaders: [
        { text: '員工', value: 'staff' },
        { text: '打卡日期', value: 'date' },
        { text: '類型', value: 'check_in_type' },
        { text: '時間', value: 'time', width: '200px' },
        { text: '打卡類型', value: 'check_in_method' },
        { text: '打卡證明', value: 'view_proof', align: 'center' },
        {
          text: '',
          value: 'saveBtn',
          sortable: false,
        },
      ],
      tableLoading: false,
      tablePage: 1,
      tablePageLimit: 20,
      tablePageLimitOptions: [20, 50, 100],
      formItemTotal: 0,
      formData: [],
      staffList: [],
      saveCheckInList: [],
      filterMonth: [],
      filterStaff: '',
      filterType: '',

      saveFilterMonth: [],
      saveFilterStaff: '',
      saveFilterType: '',

      otCount: '',
      lateCount: '',
    }
  },
  computed: {
    checkInTypeList() {
      return this.$checkInTypeList
    },
  },
  methods: {
    async GetCheckInData() {
      if (!this.tableLoading && !this.isFetching) {
        this.tableLoading = true
        this.isFetching = true

        let calculateLateOT = false

        try {
          const payload = {
            limit: this.tablePageLimit,
            page: this.tablePage - 1,
            verify_token: this.getAdminToken(),
            join_staff: true,
            order_by: ' record_date desc, staff_id desc, record_time desc, id desc ',
            check_late_ot: true,
            check_time_editable: true,
          }

          const otPayload = {}

          if (this.$validate.DataValid(this.filterMonth)) {
            if (
              this.filterMonth.length !== this.saveFilterMonth.length ||
              (this.filterMonth.length === this.saveFilterMonth.length &&
                (this.filterMonth[0] !== this.saveFilterMonth[0] || this.filterMonth[1] !== this.saveFilterMonth[1]))
            ) {
              if (this.filterMonth.length === 1) {
                payload.filter_year_month = this.filterMonth[0]

                otPayload.filter_year_month = this.filterMonth[0]
                calculateLateOT = true
              } else if (this.filterMonth.length === 2) {
                payload.filter_year_month_start = `${this.filterMonth[0]}-01`
                payload.filter_year_month_end = `${this.filterMonth[1]}-31`

                otPayload.filter_year_month_start = `${this.filterMonth[0]}-01`
                otPayload.filter_year_month_end = `${this.filterMonth[1]}-31`
                calculateLateOT = true
              }
            }
          }

          if (this.$validate.DataValid(this.filterStaff) && this.filterStaff !== this.saveFilterStaff) {
            payload.staff_id = parseInt(this.filterStaff)
            otPayload.staff_id = parseInt(this.filterStaff)

            calculateLateOT = true
          }

          if (this.$validate.DataValid(this.filterType) && this.filterType !== this.saveFilterType) {
            payload.filter_type = this.filterType
          }

          const result = await this.$XHR.api('cms_get_check_in_record_list', payload)
          this.$func.log('-----Get Check In List-----')
          this.$func.log(result)

          if (calculateLateOT) {
            this.getLateAndOTCount(otPayload)
          }

          this.saveFilterMonth = this.filterMonth
          this.saveFilterStaff = this.filterStaff
          this.saveFilterType = this.filterType

          this.formItemTotal = result.total

          const temp = []

          for (let i = 0; i < result.data.length; i++) {
            const resultData = result.data[i]

            const data = {
              id: resultData.id,
              staff: '',
              date: resultData.record_date,
              check_in_type: resultData.type,
              time: resultData.record_time,
              check_in_method: resultData.check_in_method,
              showError: false,
              late: resultData.late,
              ot: resultData.ot,
              editable: true,
            }

            if (this.$validate.DataValid(resultData.staff_data)) {
              data.staff = `${resultData.staff_data.name} (${resultData.staff_data.email})`
            }

            if (resultData.check_in_method === 'gps') {
              data.latlng = resultData.lat_lng
            } else if (resultData.check_in_method === 'image') {
              data.proof_image = resultData.proof_image
            }

            if (this.$validate.DataValid(resultData.editable)) {
              data.editable = resultData.editable
            }

            temp.push(data)
          }

          this.formData = temp

          this.saveCheckInList = result.data
        } catch (error) {
          this.$func.log('-----Get Check In List fail-----')
          this.$func.log(error)

          let msg = ''

          if (error.data === 'no permission') {
            msg = '沒有權限'
          }

          if (this.$validate.DataValid(msg)) {
            this.$store.dispatch('toggleAlertMessage', {
              show: true,
              message: msg,
              type: 'error',
              refresh: false,
              redirect: '',
            })
          }

          this.formData = []
          this.formItemTotal = 0
          this.otCount = ''
          this.lateCount = ''
        } finally {
          this.tableLoading = false
          this.isFetching = false
        }
      }
    },
    async updateCheckInTime(index) {
      if (!this.isFetching) {
        this.isFetching = true
        this.formData[index].showError = false

        if (!this.$validate.DataValid(this.formData[index].time)) {
          this.formData[index].showError = true

          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: '時間不可為空',
            type: 'error',
            refresh: false,
            redirect: '',
          })

          this.isFetching = false
        } else if (this.saveCheckInList[index].record_time === this.formData[index].time) {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: '沒有資料需要更新',
            type: 'error',
            refresh: false,
            redirect: '',
          })

          this.isFetching = false
        } else {
          try {
            const payload = {
              id: parseInt(this.formData[index].id),
              verify_token: this.getAdminToken(),
              record_time: this.formData[index].time,
            }

            const result = await this.$XHR.api('cms_update_checkin_record', payload)
            this.$func.log('-----Update check in time-----')
            this.$func.log(result)

            this.$store.dispatch('toggleAlertMessage', {
              show: true,
              message: '儲存成功',
              type: 'success',
              refresh: false,
              redirect: '',
            })

            this.isFetching = false
            this.GetCheckInData()
          } catch (error) {
            this.$func.log('-----Update check in time fail-----')
            this.$func.log(error)

            let msg = '儲存失敗'

            if (error.data === 'admin verification fail') {
              msg = ''
              this.forceLogout()
            } else if (error.data === 'no permission') {
              msg = '沒有權限'
            } else if (error.data === 'record not found') {
              msg = '打卡記錄不存在'
            } else if (error.data === 'check in time cannot exceed the check out time') {
              msg = `上班打卡不可晚於下班打卡 (${error.date} ${error.time})`
            } else if (error.data === 'related check in record not found') {
              msg = `相關上班打卡記錄不存在`
            } else if (error.data === 'check out time cannot smaller than the check in time') {
              msg = `上班打卡 (${error.date} ${error.time}) 不可早於下班打卡`
            }

            if (this.$validate.DataValid(msg)) {
              this.$store.dispatch('toggleAlertMessage', {
                show: true,
                message: msg,
                type: 'error',
                refresh: false,
                redirect: '',
              })
            }
          } finally {
            this.isFetching = false
          }
        }
      }
    },
    async getStaffData() {
      try {
        const payload = {
          verify_token: this.getAdminToken(),
        }

        const result = await this.$XHR.api('cms_get_staff', payload)
        this.$func.log('-----Get Staff-----')
        this.$func.log(result)

        const temp = []

        for (let i = 0; i < result.data.length; i++) {
          const resultData = result.data[i]

          const data = {
            value: resultData.id,
            text: `${resultData.name} (${resultData.email})`,
          }
          temp.push(data)
        }

        this.staffList = temp
      } catch (error) {
        this.$func.log('-----Get Staff fail-----')
        this.$func.log(error)
      }
    },
    async getLateAndOTCount(payload) {
      this.otCount = ''
      this.lateCount = ''

      try {
        payload.verify_token = this.getAdminToken()
        const result = await this.$XHR.api('cms_get_late_ot_count', payload)
        this.$func.log('-----Get Late and OT Count-----')
        this.$func.log(result)

        if (result.ot_count !== 0) {
          this.otCount = `加班時數: ${result.ot_count}小時`
        }

        if (result.late_count !== 0) {
          this.lateCount = `遲到日數: ${result.late_count}日`
        }
      } catch (error) {
        this.$func.log('-----Get Late and OT Count fail-----')
        this.$func.log(error)
      }
    },
    submitSearch() {
      if (
        this.$validate.DataValid(this.filterMonth) ||
        this.$validate.DataValid(this.filterStaff) ||
        this.$validate.DataValid(this.filterType)
      ) {
        this.formItemTotal = 0
        this.tablePage = 1
        this.formData = []
        this.GetCheckInData()
      }
    },
    clearSearch() {
      this.formItemTotal = 0
      this.tablePage = 1
      this.formData = []
      this.setDefaultMonth()
      this.filterStaff = ''
      this.filterType = ''

      this.saveFilterMonth = []
      this.saveFilterStaff = ''
      this.saveFilterType = ''
      this.GetCheckInData()
    },
    async downloadExcel() {
      if (!this.tableLoading && !this.isFetching) {
        this.isFetching = true

        try {
          const payload = {
            verify_token: this.getAdminToken(),
            join_staff: true,
            order_by: ' record_date desc, staff_id desc, record_time desc, id desc ',
          }

          if (this.$validate.DataValid(this.saveFilterMonth)) {
            if (this.saveFilterMonth.length === 1) {
              payload.filter_year_month = this.saveFilterMonth[0]
            } else if (this.saveFilterMonth.length === 2) {
              payload.filter_year_month_start = `${this.saveFilterMonth[0]}-01`
              payload.filter_year_month_end = `${this.saveFilterMonth[1]}-31`
            }
          }

          if (this.$validate.DataValid(this.saveFilterStaff)) {
            payload.staff_id = parseInt(this.saveFilterStaff)
          }

          if (this.$validate.DataValid(this.saveFilterType)) {
            payload.filter_type = this.saveFilterType
          }

          const result = await this.$XHR.api('cms_get_check_in_record_list', payload)
          this.$func.log('-----Get Excel Check In List-----')
          this.$func.log(result)

          const excelData = [['員工', '打卡日期', '類型', '時間', '打卡類型']]

          for (let i = 0; i < result.data.length; i++) {
            const item = result.data[i]

            const temp = []

            if (this.$validate.DataValid(item.staff_data)) {
              temp.push(`${item.staff_data.name} (${item.staff_data.email})`)
            } else {
              temp.push('')
            }

            temp.push(item.record_date)

            const type = this.$checkInTypeList.filter(obj => {
              return obj.value === item.type
            })

            if (type.length > 0) {
              temp.push(type[0].text)
            } else {
              temp.push('')
            }

            temp.push(item.record_time)

            const checkInMethod = this.$checkInMethodList.filter(obj => {
              return obj.value === item.check_in_method
            })

            if (checkInMethod.length > 0) {
              temp.push(checkInMethod[0].text)
            } else {
              temp.push('')
            }

            excelData.push(temp)
          }

          let workbook = XLSX.utils.book_new()
          let sheet1 = XLSX.utils.aoa_to_sheet(excelData)
          XLSX.utils.book_append_sheet(workbook, sheet1, 'check in record')
          XLSX.writeFile(workbook, 'check_in_record.xlsx')
        } catch (error) {
          this.$func.log('-----Get Excel Check In List fail-----')
          this.$func.log(error)

          let msg = '沒有資料可供滙出'

          if (error.data === 'no permission') {
            msg = '沒有權限'
          }

          if (this.$validate.DataValid(msg)) {
            this.$store.dispatch('toggleAlertMessage', {
              show: true,
              message: msg,
              type: 'error',
              refresh: false,
              redirect: '',
            })
          }
        } finally {
          this.isFetching = false
        }
      }
    },
    setDefaultMonth() {
      const current = new Date()
      let month = current.getMonth()
      month = month + 1 < 10 ? '0' + (month + 1) : month
      this.filterMonth = [`${current.getUTCFullYear()}-${month}`]
    },
    onTableChange(options) {
      if (options.itemsPerPage !== this.tablePageLimit) {
        this.tablePage = 1 // reset to first page if view options changed
      } else {
        this.tablePage = options.page
      }

      if (options.sortBy.length > 0) {
        this.tableSortKey = options.sortBy[0]
      }
      this.tableSortDesc = options.sortDesc.length > 0 ? options.sortDesc[0] : true

      this.tablePageLimit = options.itemsPerPage
      this.GetCheckInData()
    },
  },
  async created() {
    const check = await this.checkUserData()
    if (check) {
      this.setDefaultMonth()
      this.getStaffData()
      this.GetCheckInData()
    }
  },
}
</script>
